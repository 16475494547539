import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Typography } from '@material-ui/core';
import { setIsOpen } from '../../store/actions';
import { connect } from 'react-redux';


function NewAleart({isOpen,alertInfo, setIsOpen}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Dialog 
        open={isOpen}
        onClose={alertInfo.dialogType !== "none" ? () => setIsOpen(false) : undefined}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
        }}>
        {/* {dialogType === 1 ? <CheckCircleRoundedIcon/> : dialogType === 2 ? <ErrorRoundedIcon/> : <WarningRoundedIcon/>} */}
          {alertInfo.title}
          </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {alertInfo.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
        }}>
          {alertInfo.dialogType !=="none" && <Button onClick={()=>setIsOpen(false)} color="primary" >
            GOT IT!
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userData: state.userDataState.userData,
  user: state.authState.user,
  isOpen: state.newAlertState.isOpen,
  alertInfo:state.newAlertState.alertInfo
})

export default connect(mapStateToProps, { setIsOpen })(NewAleart)
