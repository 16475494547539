import axios from 'axios';
import { GET_MOVIES, SELECT_MOVIE, GET_SUGGESTIONS } from '../types';
import { setAlert } from './alert';

export const uploadMovieImage = (image) => async (dispatch) => {
  try {
    const token = localStorage.getItem('jwtToken');
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    };

    const data = new FormData();
    data.append('image', image); // Update the key to 'image' as per the Swagger documentation

    const url = `${process.env.REACT_APP_BE_URL}/images`; // Update the URL based on your Swagger documentation

    const response = await axios.post(url, data, { headers });

    if (response.data) {
      const id = response.data.data.id;
      dispatch(setAlert('Image Uploaded', 'success', 5000));
      return id;
    }
  } catch (error) {
    console.error('Error uploading image:', error);
    dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
  }
};

export const getMovies = () => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_BE_URL}/movies?fields=name,slug,duration,description,casts,director,releaseDate,endDate,ytTrailerID,isDraft,genres,languages,images`;
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    const movies = await response.json();
    if (response.ok) {
      dispatch({ type: GET_MOVIES, payload: movies.data });
    }
  } catch (error) {
    dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
  }
};

export const onSelectMovie = (movie) => ({
  type: SELECT_MOVIE,
  payload: movie
});

export const getMovie = (id) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BE_URL}/movies/${id}`;
  axios
    .get(url)
    .then((res) => {
      dispatch({ type: SELECT_MOVIE, payload: res.data.data });
    })
    .catch((error) => {
      dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
    });
};

export const getMovieSuggestion = (id) => async (dispatch) => {
  try {
    const url = '/movies/usermodeling/' + id;
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    });
    const movies = await response.json();
    if (response.ok) {
      dispatch({ type: GET_SUGGESTIONS, payload: movies });
    }
  } catch (error) {
    dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
  }
};

export const addMovie = (portraitImage, landscapeImage, newMovie) => async (dispatch) => {
  try {
    const portraitImageId = portraitImage ? await dispatch(uploadMovieImage(portraitImage)) : null;
    const landscapeImageId = landscapeImage ? await dispatch(uploadMovieImage(landscapeImage)) : null;

    const token = localStorage.getItem('jwtToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };

    const url = `${process.env.REACT_APP_BE_URL}/movies`;
    let requestData;
    if (portraitImage && landscapeImage) {
      requestData = {
        name: newMovie.name,
        description: newMovie.description,
        duration: newMovie.duration,
        director: newMovie.director,
        casts: newMovie.casts,
        releaseDate: newMovie.releaseDate,
        endDate: newMovie.endDate,
        ytTrailerID: newMovie.ytTrailerID,
        genres: [newMovie.genres],
        images: [portraitImageId, landscapeImageId],
        languages: [newMovie.languages],
        slug: newMovie.slug,
        isDraft: false
      }
    } else {
      requestData = {
        name: newMovie.name,
        description: newMovie.description,
        duration: newMovie.duration,
        director: newMovie.director,
        casts: newMovie.casts,
        releaseDate: newMovie.releaseDate,
        endDate: newMovie.endDate,
        ytTrailerID: newMovie.ytTrailerID,
        genres: [newMovie.genres],
        languages: [newMovie.languages],
        slug: newMovie.slug,
        isDraft: false
      }
    }

    const response = await axios.post(url, requestData, { headers });

    if (response.data) {
      dispatch(setAlert('Movie has been saved!', 'success', 5000));
      dispatch(getMovies());
    }
  } catch (error) {
    console.error('Error adding movie:', error);
    dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
  }
};

export const updateMovie = (movieId, movie, portraitImage, landscapeImage) => async (dispatch) => {
  try {
    const portraitImageId = portraitImage ? await dispatch(uploadMovieImage(portraitImage)) : null;

    const landscapeImageId = landscapeImage ? await dispatch(uploadMovieImage(landscapeImage)) : null;

    const token = localStorage.getItem('jwtToken');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };

    const url = `${process.env.REACT_APP_BE_URL}/movies/updatemovie/${movieId}`;
    let requestData;
    if (portraitImage && landscapeImage) {
      requestData = {
        name: movie.name,
        description: movie.description,
        duration: movie.duration,
        director: movie.director,
        casts: movie.casts,
        releaseDate: movie.releaseDate,
        endDate: movie.endDate,
        ytTrailerID: movie.ytTrailerID,
        genres: [movie.genres],
        images: [portraitImageId, landscapeImageId],
        languages: [movie.languages],
        slug: movie.slug,
        isDraft: false
      }
    } else {
      requestData = {
        name: movie.name,
        description: movie.description,
        duration: movie.duration,
        director: movie.director,
        casts: movie.casts,
        releaseDate: movie.releaseDate,
        endDate: movie.endDate,
        ytTrailerID: movie.ytTrailerID,
        genres: [movie.genres],
        languages: [movie.languages],
        slug: movie.slug,
        isDraft: false
      }
    }

    const response = await axios.put(url, requestData, { headers });

    if (response.data) {
      dispatch(onSelectMovie(null));
      dispatch(setAlert('Movie has been updated!', 'success', 5000));
      dispatch(getMovies());
    }
  } catch (error) {
    console.error('Error updating movie:', error);
    dispatch(setAlert(error?.response?.data?.error?.message || 'Internal Server Error', 'error', 5000));
  }
};

export const removeMovie = (movieId) => async (dispatch) => {
  const token = localStorage.getItem('jwtToken');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  };
  const url = `${process.env.REACT_APP_BE_URL}/movies/deletemovie/${movieId}`;
  axios
    .delete(url, { headers })
    .then((res) => {
      dispatch(getMovies());
      dispatch(onSelectMovie(null));
      dispatch(setAlert('Movie have been Deleted!', 'success', 5000));
    })
    .catch((error) => {
      dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
    });
};
