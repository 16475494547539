import axios from 'axios';
import { GET_OTHER_THEATRE, ADD_OTHER_THEATRE, TOGGLE_DIALOG_OTHER_THEATRE, SELECT_OTHER_THEATRES, SELECT_ALL_OTHER_THEATRES, DELETE_OTHER_THEATRE } from "../types";
import { setAlert } from './alert';

export const toggleDialogOtherTheatre = () => ({ type: TOGGLE_DIALOG_OTHER_THEATRE });

export const selectOtherTheatre = otherTheatre => ({
    type: SELECT_OTHER_THEATRES,
    payload: otherTheatre
});

export const selectAllOtherTheatres = () => ({ type: SELECT_ALL_OTHER_THEATRES });

export const getOtherTheatres = () => async (dispatch) => {
    const url = `${process.env.REACT_APP_BE_URL}/othertheatre`;
    axios
        .get(url)
        .then((res) => {
            dispatch({ type: GET_OTHER_THEATRE, payload: res?.data?.data });
        })
        .catch((error) => {
            dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
        });
};

export const AddOtherTheatre = otherTheatre => async dispatch => {
    const token = localStorage.getItem('jwtToken');
    const url = `${process.env.REACT_APP_BE_URL}/othertheatre/add-shows`;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    await axios.post(url, {
        redirectLink: otherTheatre.redirectLink,
        theatreName: otherTheatre.theatreName,
        movieId: otherTheatre.movieId,
        city: otherTheatre.city,

    }, { headers })
        .then((res) => {
            dispatch(setAlert('Other theatre Created', 'success', 5000));
            dispatch(getOtherTheatres());
            return { status: 'success', message: 'other theatre Created' };
        }).catch((error) => {
            dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
            return {
                status: 'error',
                message: ' Other theatre have not been saved, try again.'
            };
        })
}

export const updateOtherTheatre = (otherTheatre, id) => async dispatch => {
    const token = localStorage.getItem('jwtToken');
    const url = `${process.env.REACT_APP_BE_URL}/othertheatre/update-show/${id}`;
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    try {
        axios.put(url, {
            redirectLink: otherTheatre.redirectLink,
            theatreName: otherTheatre.theatreName,
            movieId: otherTheatre.movieId,
            city: otherTheatre.city,
        }, { headers })
            .then((res) => {
                dispatch(setAlert(res?.data?.message, 'success', 5000));
                dispatch(getOtherTheatres());
                return { status: 'success', message: 'OtherTheatre updated' };
            })
            .catch((error) => {
                dispatch(setAlert(error?.response?.data?.message, 'error', 5000));
                return {
                    status: 'error',
                    message: ' OtherTheatre have not been updated, try again.'
                };
            })
    } catch (error) {
        dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
        return {
            status: 'error',
            message: ' OtherTheatre have not been updated, try again.'
        };
    }
};

export const deleteOtherTheatre = id => async dispatch => {
    const token = localStorage.getItem('jwtToken');
    const url = `${process.env.REACT_APP_BE_URL}/othertheatre/delete-show/${id}`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    axios.delete(url, { headers })
        .then((res) => {
            dispatch(getOtherTheatres());
            dispatch({ type: DELETE_OTHER_THEATRE, payload: id });
            dispatch(setAlert('OtherTheatre Deleted', 'success', 5000));
            return { status: 'success', message: 'OtherTheatre Removed' };
        })
        .catch((error) => {
            dispatch(setAlert(error?.response?.data?.error?.message, 'error', 5000));
            return {
                status: 'error',
                message: ' OtherTheatre have not been deleted, try again.'
            };
        })
};
