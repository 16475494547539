import { ADD_OTHER_THEATRE, DELETE_OTHER_THEATRE, GET_OTHER_THEATRE, SELECT_ALL_OTHER_THEATRES, SELECT_OTHER_THEATRES, TOGGLE_DIALOG_OTHER_THEATRE } from "../types";

const initialState = {
    otherTheatres: [],
    selectedOtherTheatres: [],
    openDialog: false,
}

const getOtherTheatres = (state, payload) => ({
    ...state,
    otherTheatres: payload
})

const toggleDialog = state => ({
    ...state,
    openDialog: !state.openDialog
});

const selectOtherTheatre = (state, payload) => {
    const { selectedOtherTheatres } = state;

    const selectedIndex = selectedOtherTheatres.indexOf(payload);
    let newSelected = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedOtherTheatres, payload);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedOtherTheatres.slice(1));
    } else if (selectedIndex === selectedOtherTheatres.length - 1) {
        newSelected = newSelected.concat(selectedOtherTheatres.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selectedOtherTheatres.slice(0, selectedIndex),
            selectedOtherTheatres.slice(selectedIndex + 1)
        );
    }

    return {
        ...state,
        selectedOtherTheatres: newSelected
    };
};

const selectAllOtherTheatre = state => ({
    ...state,
    selectedOtherTheatres: !state.selectedOtherTheatres.length
        ? state.otherTheatres.map(otherTheatre => otherTheatre.id)
        : []
});

const deleteOtherTheatre = (state, payload) => ({
    ...state,
    selectedOtherTheatres: state.selectedOtherTheatres.filter(
        element => element !== payload
    )
});

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_OTHER_THEATRE:
            return getOtherTheatres(state, payload);
        case TOGGLE_DIALOG_OTHER_THEATRE:
            return toggleDialog(state)
        case SELECT_OTHER_THEATRES:
            return selectOtherTheatre(state, payload)
        case SELECT_ALL_OTHER_THEATRES:
            return selectAllOtherTheatre(state)
        case DELETE_OTHER_THEATRE:
            return deleteOtherTheatre(state, payload)
        default:
            return state
    }
}