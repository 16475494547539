import { useDispatch } from "react-redux";
import { ADD_USER_TOKEN, ADD_USER_DATA, SET_IS_VERIFIED,SET_PAYMENT_INFO, SET_SELECTED_SEATSDATA, SET_IS_VISITED} from "../types";

export const setUserToken = (userToken) =>({
    type: ADD_USER_TOKEN,
    payload: {userToken}
})

export const setUserData = (userData) =>({
    type: ADD_USER_DATA,
    payload:{
        name:userData.name,
        email:userData.email,
        phone:userData.phone
    }
})

export const setPaymentInfo = (paymentData) =>({
    type: SET_PAYMENT_INFO,
    payload:{
        number:paymentData.number,
        date:paymentData.date,
        ccv:paymentData.ccv,
    }
})

export const setIsVerified = (isVerified) =>({
    type: SET_IS_VERIFIED,
    payload:isVerified
})

export const setSelectedSeatsData = (selectedSeats)=>({
    type:SET_SELECTED_SEATSDATA,
    payload:selectedSeats
})

export const setUserIsVisited = (isVisited)=>({
    type:SET_IS_VISITED,
    payload:isVisited
})