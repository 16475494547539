import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import users from './users';
import movies from './movies';
import cinemas from './cinemas';
import reservations from './reservations';
import showtimes from './showtimes';
import checkout from './checkout';
import userData from './userData';
import newAlert from './newAlert'
import listAllShow from './listAllShow';
import dataFromServer from './dataFromServer';
import booking from './booking';
import otherTheatre from './otherTheatre';
import city from './city';
import event from './event';

export default combineReducers({
  alertState: alert,
  authState: auth,
  userState: users,
  movieState: movies,
  cinemaState: cinemas,
  reservationState: reservations,
  showtimeState: showtimes,
  checkoutState: checkout,
  userDataState: userData,
  newAlertState: newAlert,
  listAllShowState: listAllShow,
  dataFromServerState: dataFromServer,
  bookingState: booking,
  otherTheatreState: otherTheatre,
  cityState: city,
  eventState: event
});
