import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  VERIFICATION_SUCCESS,
  VERIFICATION_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT
} from '../types';

const initialState = {
  token: localStorage.getItem('jwtToken'),
  isAuthenticated: false,
  loading: false,
  user: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return { ...state, user: payload, isAuthenticated: true, loading: false };
    case REGISTER_SUCCESS:
      return { ...state, ...payload, isAuthenticated: false, loading: false };
    case LOGIN_SUCCESS:
      localStorage.setItem('jwtToken', payload.accessToken);
      localStorage.setItem('jwtRefreshToken', payload.refreshToken);
      return { ...state, ...payload, isAuthenticated: true, loading: false };
    case REGISTER_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('jwtRefreshToken');
      return initialState;
    default:
      return state;
  }
};
