import axios from "axios";
import { GET_GENRES, GET_LANGUAGES } from "../types";

export const getGenres = () => async (dispatch) => {
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    const url = `${process.env.REACT_APP_BE_URL}/genres/`
    axios.get(url, { headers })
        .then((res) => {
            dispatch({ type: GET_GENRES, payload: res.data.data });
        })
        .catch((err) => {
        })
}

export const getLanguages = () => async (dispatch) => {
    const token = localStorage.getItem('jwtToken');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    const url = `${process.env.REACT_APP_BE_URL}/languages/`
    axios.get(url, { headers })
        .then((res) => {
            dispatch({ type: GET_LANGUAGES, payload: res.data.data });
        })
        .catch((err) => {
        })
}