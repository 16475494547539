import {
    GET_LISTALLSHOWS,
    GET_LISTALLSHOW,
    TOGGLE_DIALOG,
    SELECT_LISTALLSHOWS,
    SELECT_ALL_LISTALLSHOWS,
    DELETE_LISTALLSHOW
  } from '../types';
  
  const initialState = {
    listAllShows: [],
    selectedListAllShows: [],
    openDialog: false,
    listAllShow: null
  };
  
  const getListAllShows = (state, payload) => ({
    ...state,
    listAllShows: payload
  });
  
  const getListAllShow = (state, payload) => ({
    ...state,
    listAllShow: payload
  });
  
  const toggleDialog = state => ({
    ...state,
    openDialog: !state.openDialog
  });
  
  const selectListAllShow = (state, payload) => {
    const { selectedListAllShows } = state;
  
    const selectedIndex = selectedListAllShows.indexOf(payload);
    let newSelected = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedListAllShows, payload);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedListAllShows.slice(1));
    } else if (selectedIndex === selectedListAllShows.length - 1) {
      newSelected = newSelected.concat(selectedListAllShows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedListAllShows.slice(0, selectedIndex),
        selectedListAllShows.slice(selectedIndex + 1)
      );
    }
  
    return {
      ...state,
      selectedListAllShows: newSelected
    };
  };
  
  const selectAllListAllShows = state => ({
    ...state,
    selectedListAllShows: !state.selectedListAllShows.length
      ? state.listAllShows.map(listAllShow => listAllShow._id)
      : []
  });
  
  const deleteListAllShow = (state, payload) => ({
    ...state,
    selectedListAllShows: state.selectedListAllShows.filter(
      element => element !== payload
    )
  });
  
  export default (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case GET_LISTALLSHOWS:
        return getListAllShows(state, payload);
      case GET_LISTALLSHOW:
        return getListAllShow(state, payload);
      case TOGGLE_DIALOG:
        return toggleDialog(state);
      case SELECT_LISTALLSHOWS:
        return selectListAllShow(state, payload);
      case SELECT_ALL_LISTALLSHOWS:
        return selectAllListAllShows(state);
      case DELETE_LISTALLSHOW:
        return deleteListAllShow(state, payload);
      default:
        return state;
    }
  };
  