import { GET_EVENT_BOOKINGS, GET_EVENTS, SELECT_EVENT } from "../types";

const initialState = {
    events: [],
    selectedEvent: null,
    eventBookings: []
}

const getEvents = (state, payload) => ({
    ...state,
    events: payload
})

const onSelectEvent = (state, payload) => ({
    ...state,
    selectedEvent: payload
});

const getEventBookings = (state, payload) => ({
    ...state,
    eventBookings: payload
});

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_EVENTS:
            return getEvents(state, payload);
        case SELECT_EVENT:
            return onSelectEvent(state, payload);
        case GET_EVENT_BOOKINGS:
            return getEventBookings(state, payload);
        default:
            return state
    }
}