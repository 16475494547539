import { ALERT_INFO, SWITCH_ALERT } from "../types";

const initialState ={
    isOpen:false,
    alertInfo:{
        content:'',
        dialogType:'',
        title:''
    }
}

const setIsOpen = (state, payload) =>({
    ...state,
    isOpen: payload
})

const setAlertInfo = (state,payload) =>({
    ...state,
    alertInfo:{
        content:payload.content,
        dialogType:payload.dialogType,
        title:payload.title
    }
})

export default (state = initialState, action) =>{
    const {type, payload} = action

    switch(type){
        case SWITCH_ALERT:
            return setIsOpen(state,payload);
        case ALERT_INFO:
            return setAlertInfo(state,payload);
        default:
            return state
    }
}