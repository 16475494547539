import React from 'react';
import { Divider, Typography, Link } from '@material-ui/core';
import useStyles from './styles';
import logo from '../../../../assets/Images/edit.png';
import './style.css';

export default function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear(); // Get the current year dynamically

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* First Column */}
        <div className="footer-column1">
          <img src={logo} alt="Logo" className="logo" />
          <div className="content-description">
            <p>
              We are thrilled to have you here. zineflix is your ultimate
              destination for all your movie booking needs. Whether you're
              looking to catch the latest blockbuster, enjoy a classic film, or
              discover indie gems, we've got you covered. Our user-friendly
              platform ensures a seamless and enjoyable experience, from
              browsing movie listings to securing your tickets.
            </p>
          </div>
          <div className="social-links">
            <a href="https://www.facebook.com/profile.php?id=100071265075336">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://x.com/Zineflix?t=JoN3UyXp6mI5hkM2QatvZQ&s=09">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com/zineflixofficial?utm_source=qr&igsh=MXI1emduaTY3NXF2dw==">
              <i className="fab fa-instagram"></i>
            </a>
            {/* <a href="#">
              <i className="fab fa-linkedin-in"></i>
            </a> */}
          </div>
        </div>

        {/* Second Column */}
        <div className="footer-column2">
          {/* First Row */}
          <div className="footer-row">
            {/* Phone */}
            <div className="footer-column3">
              <div className="icon-container phone">
                <i className="fas fa-phone"></i>
              </div>
              <div>
                <h4>Phone</h4>
                <a
                  style={{ color: 'white', textDecoration: 'none' }}
                  href="https://wa.me/4917656806226">
                  +49 17 6568 06226
                </a>
              </div>
            </div>
            {/* Address */}
            <div className="footer-column3">
              <div className="icon-container">
                <i className="fas fa-map-marker-alt"></i>
              </div>
              <div>
                <h4>Address</h4>
                <p>Frankfurt, Germany</p>
              </div>
            </div>
            {/* Email */}
            <div className="footer-column3">
              <div className="icon-container">
                <i className="far fa-envelope"></i>
              </div>
              <div>
                <h4>Email</h4>
                <a
                  style={{ color: 'white', textDecoration: 'none' }}
                  href="mailto:Contact@zineflix.com">
                  Contact@zineflix.com
                </a>
              </div>
            </div>
          </div>

          <hr className="row-divider" />

          {/* Second Row */}
          <div className="footer-row">
            <div className="footer-column4">
              <h4 class="h4">Company</h4>
              <ul className="footer-list">
                <li>
                  <i class="fas fa-dot-circle company-icon"></i>
                  <span class="icon-text">Home</span>
                </li>
                <li>
                  <i class="fas fa-dot-circle company-icon"></i>
                  <span class="icon-text">Now Showing</span>
                </li>
                <li>
                  <i class="fas fa-dot-circle company-icon"></i>
                  <span class="icon-text">Coming Soon</span>
                </li>
                <li>
                  <i class="fas fa-dot-circle company-icon"></i>
                  <span class="icon-text">Contact Us</span>
                </li>
              </ul>
            </div>
            <div className="footer-column4">
              <h4 class="h4">Pages</h4>
              <ul className="footer-list">
                <li>
                  <i class="fas fa-dot-circle company-icon"></i>
                  <span class="icon-text">Privacy Policy</span>
                </li>
                <li>
                  <i class="fas fa-dot-circle company-icon"></i>
                  <span class="icon-text">Team & Conditions</span>
                </li>
                <li>
                  <i class="fas fa-dot-circle company-icon"></i>
                  <span class="icon-text">Complains</span>
                </li>
              </ul>
            </div>
            <div className="footer-column4">
              <h4 class="h4">Newsletter</h4>
              <p>
                Sign up for our newsletter to get updated information, news, and
                free insights.
              </p>
              <div className="newsletter-input-container">
                <input
                  type="email"
                  className="newsletter-input"
                  placeholder="Enter your email"
                />
                <button className="newsletter-button">Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider className="footer-divider" />
      <div className="footer-copyright">
        <div className="copyright-text">
          © {currentYear} zineflix.com All rights reserved.
        </div>
        <div className="footer-links">
          {/* <Link href="#" className={classes.footerLink}>
            Term of Use
          </Link>{' '}
          |
          <Link href="#" className={classes.footerLink}>
            Privacy Policy
          </Link>{' '}
          |
          <Link href="#" className={classes.footerLink}>
            Cookie Policy
          </Link> */}
          <Link href="https://www.matrixmantra.lk/">
            Web Design & Development by Matrix Mantra
          </Link>
        </div>
      </div>
    </footer>
  );
}
