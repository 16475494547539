import { ADD_USER_TOKEN, ADD_USER_DATA, SET_IS_VERIFIED, SET_PAYMENT_INFO,SET_SELECTED_SEATSDATA, SET_IS_VISITED} from "../types";

const initialState = {
    userToken: null,
    userData: {
        name: '',
        email: '',
        phone: ''
    },
    isVerified: false,
    paymentInfo: {
        number: '',
        date: '',
        ccv: '',
    },
    selectedSeats:[],
    isVisited:false
}

const setUserToken = (state, payload) => ({
    ...state,
    userToken: payload
})

const setUserIsVisited = (state, payload) => ({
    ...state,
    isVisited: payload
})

const setSelectedSeatsData = (state, payload) => ({
    ...state,
    selectedSeats: payload
})

const setUserData = (state, payload) => ({
    ...state,
    userData: {
        ...state.userData,
        name: payload.name,
        email: payload.email,
        phone: payload.phone
    }
})

const setPaymentInfo = (state, payload) => ({
    ...state,
    paymentInfo: {
        ...state.paymentInfo,
        number: payload.number,
        date: payload.date,
        ccv: payload.ccv,
    }
})

const setIsVerified = (state, payload) => ({
    ...state,
    isVerified: payload
})

export default (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case ADD_USER_TOKEN:
            return setUserToken(state, payload);
        case ADD_USER_DATA:
            return setUserData(state, payload);
        case SET_IS_VERIFIED:
            return setIsVerified(state, payload);
        case SET_PAYMENT_INFO:
            return setPaymentInfo(state, payload);
        case SET_SELECTED_SEATSDATA:
            return setSelectedSeatsData(state, payload);
        case SET_IS_VISITED:
            return setUserIsVisited(state, payload);
        default:
            return state
    }
}