import React, { forwardRef } from 'react';
import TextField from '@material-ui/core/TextField';

const PhoneNumberInput = (props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      variant="outlined"
      name="phone"
      autoComplete="tel"
    />
  );
};
export default forwardRef(PhoneNumberInput);
