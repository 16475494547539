import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: '#fff'
  },
  item_content: {
    margin: '10px 0'
  },
  otp_wrapper: {
    margin: theme.spacing(0, 2)
  },
  succes_title: {
    fontSize: '18px',
    fontWeight: '700',
    margin: '10px 0'
  },
  apply_job_sub_title: {
    fontSize: '14px',
    fontWeight: '500',
    margin: '15px 0'
  },
  input_otp_style: {
    width: '40px !important',
    height: '40px',
    margin: '10px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #dfdfdf'
  },
  error_otp: {
    border: `1px solid ${theme.palette.error.main} !important`
  },
  focus_otp: {
    border: `1px solid ${theme.palette.primary.main} !important`
  },
  disable_style: {
    border: `1px solid #696969 !important`,
    backgroundColor: '#D3D3D3'
  },
  main_error_text: {
    color: theme.palette.error.main,
    margin: '15px 0',
    fontSize: '14px'
  },
  main_success_text: {
    color: theme.palette.primary.dark,
    margin: '15px 0',
    fontSize: '14px'
  },
  otp_resend_wrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0'
  },
  small_title: {
    fontSize: '14px',
    fontWeight: '500',
    marginRight: '5px'
  }
}));

export default styles;
