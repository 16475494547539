import { BorderColor } from '@material-ui/icons';

export default (theme) => ({
  navbar: {
    position: 'fixed',
    // marginTop:'9px',
    top: 0,
    left: 0,
    width: '100%',
    // padding: '1.25rem 20px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    transform: 'translate3d(0,0,0)',
    backfaceVisibility: 'hidden',
    zIndex: 15,
    transition: 'all 300ms ease-in',
    background: '#000',
    fontFamily: 'sans-serif',
    borderTop: '6px solid #ff9d1e'
  },

  navbarColor: {
    background: theme.palette.background.white,
    transition: 'all 200ms ease-in'
  },
  logoLink: {
    display: 'inline-block',
    paddingTop: '.15rem',
    paddingBottom: '.15rem',
    marginRight: '20px',
    fontSize: '1.5rem',
    lineHeight: 'inherit',
    whiteSpace: 'nowrap',
    textDecoration: 'none'
  },
  logo: {
    maxHeight: '2.4rem',
    width: 'auto',
    fontSize: '2rem',
    // letterSpacing: '1px',
    // letterSpacing: '1px',
    // color: theme.palette.common.white
    color: '#fff',
    fontFamily: 'fantasy',
    // marginLeft : '3em'
    marginLeft: '2.1em'
  },
  [theme.breakpoints.down('767')]: {
    logo: {
      fontSize: '15px',

      width: 'auto'
    }
    // Other styles for smaller screens...
  },
  navLinks: {
    marginLeft: 'auto',
    display: 'flex'
  },
  navLink: {
    position: 'relative',
    color: theme.palette.common.white,
    margin: '0 1rem',
    fontSize: '1.1rem',
    fontWeight: '600',
    cursor: 'pointer',
    textDecoration: 'none',
    zIndex: 2,
    padding: '20px 17px 20px 17px',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      // border: '1px solid #ff9d1e',
      zIndex: -5,
      padding: '19px 9px',
      boxSizing: 'border-box',
      // transform: translateX(0),
      transition: '.3s transform linear, .3s height linear .5s'
    },
    '&:hover': {
      color: '#ff9d1e'
    },
    '&:hover:after': {
      opacity: 1,
      border: '1px solid #ff9d1e',
      animation:
        '$buildUpBorderTop 0.5s forwards, $buildUpBorderRight 0.5s forwards, $buildUpBorderBottom 0.5s forwards, $buildUpBorderLeft 0.5s forwards'
    }
  },
  '@keyframes buildUpBorderTop': {
    '0%': {
      borderTopWidth: '1px',
      BorderColor: '#ff9d1e',
      opacity: 0
    },
    '100%': {
      borderTopWidth: '1px',
      BorderColor: '#ff9d1e',
      opacity: 1
    }
  },
  '@keyframes buildUpBorderRight': {
    '0%': {
      borderRightWidth: 0,
      opacity: 0
    },
    '100%': {
      borderRightWidth: '1px',
      opacity: 1,
      BorderColor: '#ff9d1e'
    }
  },
  '@keyframes buildUpBorderBottom': {
    '0%': {
      borderBottomWidth: 0,
      BorderColor: '#ff9d1e',
      opacity: 0
    },
    '100%': {
      borderBottomWidth: '1px',
      BorderColor: '#ff9d1e',
      opacity: 1
    }
  },
  '@keyframes buildUpBorderLeft': {
    '0%': {
      borderLeftWidth: 0,
      opacity: 0
    },
    '100%': {
      borderLeftWidth: '1px',
      BorderColor: '#ff9d1e',
      opacity: 1
    }
  },

  navAccount: {
    marginLeft: 'auto'
    // marginRight: theme.spacing(3)
  },
  navMobile: { marginRight: theme.spacing(1) },
  navIcon: {
    display: 'none',
    height: '30px',
    width: '30px',
    position: 'relative',
    zIndex: 2,
    cursor: 'pointer',
    '&:hover $navIconLine__left, &:hover $navIconLine__right': {
      width: '30px'
    }
  },
  navIconLine: {
    height: '2px',
    width: '30px',
    display: 'block',
    backgroundColor: theme.palette.common.white,
    marginBottom: '7px',
    transition: ' transform .2s ease, background-color .5s ease'
  },
  navIconLine__left: {
    width: '20px',
    transition: 'all 200ms linear'
  },
  navIconLine__right: {
    width: '20px',
    transition: 'all 200ms linear'
  },

  nav: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    paddingLeft: 0,
    marginBottom: 0,
    '&:before, &:after': {
      content: '""',
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      background: 'rgba(255, 255, 255, 0.1)',
      zIndex: '-1',
      transition: 'transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s',
      transform: 'translateX(0%) translateY(-100%)'
    },
    '&:before': {
      background: theme.palette.common.black,
      transitionDelay: '0s'
    },
    '&:after': {
      transitionDelay: '.1s'
    }
  },
  navActive: {
    visibility: 'visible',
    zIndex: 9,
    '&:before': {
      transitionDelay: '0s',
      transform: 'translateX(0%) translateY(0%)'
    },
    '&:after': {
      transitionDelay: '.1s',
      transform: 'translateX(0%) translateY(0%)'
    },
    '& $navContent': {
      visibility: 'visible'
    },
    '& $navContent $currentPageShadow': {
      transitionDelay: '.5s',
      opacity: 0.03,
      marginTop: '0'
    },
    '& $navContent $innerNavListItem': {
      transitionDelay: '.5s',
      opacity: 1,
      transform: 'translateX(0%)',
      transition: 'opacity .3s ease, transform .3s ease, color .3s ease'
    }
  },
  // navContent: {
  //   position: 'fixed',
  //   visibility: 'hidden',
  //   // top: '50%',
  //   top: 0, // Position at the top of the screen
  //   left: 0, // Align to the left side
  //   transform: 'translate(0%, -50%)',
  //   width: '100%'
  // },
  navContent: {
    top: '10%',
    left: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column', // Ensure items stack vertically
    zIndex: 999, // Adjust z-index to ensure it's above other content
    position: 'fixed',
    // backgroundColor: '#F5F6FA', // Background color of the menu
    paddingLeft: 0, // Adjust left padding for spacing
    margin: 0, // Remove default margin
    width: 380, // Set the width of the menu
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' // Optional: Add box shadow for depth
  },
  currentPageShadow: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'sans-serif',
    fontSize: '10rem',
    fontWeight: 800,
    marginTop: '25px',
    color: '#fff',
    opacity: 0,
    transition: 'all 300ms linear'
  },
  innerNav: {
    position: 'relative',
    padding: 0,
    margin: 0,
    zIndex: 2
  },
  innerNavListItem: {
    opacity: 0,
    position: 'relative',
    display: 'block',
    textAlign: 'left',
    fontSize: '4rem',
    lineHeight: '1.05',
    letterSpacing: '3px',
    transform: 'translate(0%, 100%)',
    transition: 'opacity .2s ease, transform .3s ease'
    // borderBottom: '1px solid white', // Add white border under each list item

    // '&:hover': {
    //   backgroundColor: 'yellow',
    //   color: 'black',
    // },
  },
  innerNavLink: {
    position: 'relative',
    color: theme.palette.common.white,
    padding: '0 .5rem',
    margin: '0 1rem',
    fontSize: '2rem',
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'none',
    zIndex: 2,
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      opacity: 0,
      height: 0,
      marginTop: '3px',
      backgroundColor: 'rgba(255,255,255,0.5)',
      transition: 'all 200ms linear',
      zIndex: 1
    }

    // '&:hover:after': {
    //   opacity: 1,
    //   height: 15
    // }
  },
  [theme.breakpoints.down('sm')]: {
    navIcon: { display: 'block', left: '0', top: '10px', position: 'absolute' },
    navLinks: { display: 'none' }
  },
  pad: {
    backgroundColor: '#ff9d1e',
    padding: '5px 0px 0px 1px',
    position: 'fixed',
    width: '100%',
    zIndex: 16
  },
  logo: {
    width: '180px'
  },
  [theme.breakpoints.down('767')]: {
    logo: {
      justifyContent: 'center',
      marginLeft: '20px',
      width: '200px'
    },
    navIcon: {
      paddingLeft: '10px'
    }
  }
});
